import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import HeroCS from "../components/case-study-hero";
import CaseStudyReviewSection from "../components/case-study-review-section";
import DevicesImageSection from "../components/devices-image-section";
import Layout from "../components/layout";
import MobileImagesSection from "../components/mobile-images-section";
import ResultsSectionCaseStudies from "../components/results-section-case-studies";
import WhatWeDidSection from "../components/what-we-did-section";

const ECFCaseStudyPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Ninja-heroimage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			laptopImg: wpMediaItem(title: { eq: "Laptop-ecf" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ipadImg: wpMediaItem(title: { eq: "Tablet-ecf" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			phoneImg: wpMediaItem(title: { eq: "Phone-ecf" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			phoneImg2: wpMediaItem(title: { eq: "hivehr-phone" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			phoneImg3: wpMediaItem(title: { eq: "Colston-phone" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			phoneImg4: wpMediaItem(title: { eq: "Phone-ecf" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Ninja Arena Case Study" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "Web Design Eastbourne" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			wpWhatWeDidCaseStudy(
				whatWeDidCaseStudyFields: {
					pageTitle: { eq: "Ninja arena case study" }
				}
			) {
				title
				whatWeDidCaseStudyFields {
					description
					pageTitle
					seo {
						description
						title
					}
					servicesProvided {
						branding
						seo
						webDesign
					}
					webDesign {
						description
						title
					}
					branding {
						description
						title
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Ninja Arena Case Study",
				item: {
					url: `${siteUrl}/ninja-arena-case-study`,
				},
			},
		],
	};
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/ninja-arena-case-study`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<HeroCS
						button1="white-link"
						button1Text="Get in touch"
						button1Link="/contact-us"
						button1Var="dark-blue"
						button2="primary-link-dark"
						button2Text="Get in touch"
						button2Link="/contact-us"
						button2Var="white"
						image={data.heroImg.gatsbyImage}
						imageAlt={data.heroImg.altText}
						title={<span>NINJA ARENA</span>}
						description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium"
						backgroundColour="transparent linear-gradient(64deg, #023A7F 0%, #023A7F 65%, #F17839 100%) 0% 0% no-repeat padding-box"
					/>
				</section>

				<WhatWeDidSection whatWeDid={data.wpWhatWeDidCaseStudy} />
				<DevicesImageSection
					image1={data.laptopImg.gatsbyImage}
					imageAlt1={data.laptopImg.altText}
					image2={data.ipadImg.gatsbyImage}
					imageAlt2={data.ipadImg.altText}
					image3={data.phoneImg.gatsbyImage}
					imageAlt3={data.phoneImg.altText}
				/>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-4 pb-5 text-primary">Dolorem Sit?</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco
									laboris nisi ut aliquip ex ea commodo consequat.
								</p>
								<p>
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore eu fugiat nulla pariatur. Excepteur sint
									occaecat cupidatat non proidentt.
								</p>
								<p>
									Laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Lorem
									ipsum dolor sit amet, consectetur adipiscing elit.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<ResultsSectionCaseStudies
					title="Results"
					mainText={
						<span>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat.
							</p>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat.
							</p>
						</span>
					}
					image={data.heroImg.gatsbyImage}
					imageAlt={data.heroImg.altText}
					result1Title="40%"
					result1Text="Et dolore magna"
					result2Title="66%"
					result2Text="Et dolore magna"
					result3Title="66%"
					result3Text="Et dolore magna"
					bgColour="transparent linear-gradient(80deg, #F27A39 0%, #F4B126 100%) 0% 0% no-repeat padding-box"
				/>
				<CaseStudyReviewSection
					quoteColour="#F4B126"
					textColour="#F17839"
					name="Ninja Arena Eastbourne"
					review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris."
				/>
				<MobileImagesSection
					title="Lorem ipsum dolor"
					image1={data.phoneImg2.gatsbyImage}
					imageAlt1={data.phoneImg2.altText}
					image2={data.phoneImg3.gatsbyImage}
					imageAlt2={data.phoneImg3.altText}
					image3={data.phoneImg4.gatsbyImage}
					imageAlt3={data.phoneImg4.altText}
				/>
			</Layout>
		</>
	);
};

export default ECFCaseStudyPage;
